import { useAccountDrawer } from 'components/AccountDrawer/MiniPortfolio/hooks'
import { ButtonPrimary } from 'components/Button'
import { useSellFormContext } from 'pages/Swap/Sell/SellFormContext'
import { useMemo } from 'react'
import { Trans } from 'react-i18next'
import { useAccount } from 'wagmi'

interface SellFormButtonProps {
  forceDisabled?: boolean
}

export function SellFormButton({ forceDisabled }: SellFormButtonProps) {
  const account = useAccount()
  const accountDrawer = useAccountDrawer()

  const { sellFormState, derivedSellFormInfo, setSellFormState } = useSellFormContext()
  const { inputAmount } = sellFormState
  const { notAvailableInThisRegion, quotes, fetchingQuotes, error } = derivedSellFormInfo

  const sellButtonState = useMemo(() => {
    // if (!account.isConnected) {
    //   return {
    //     label: <Trans i18nKey="common.connectWallet.button" />,
    //     disabled: false,
    //     onClick: accountDrawer.open,
    //     Component: ButtonLight,
    //   }
    // }
    if (!inputAmount || forceDisabled) {
      return {
        label: <Trans i18nKey="common.noAmount.error" />,
        disabled: true,
        onClick: undefined,
        Component: ButtonPrimary,
      }
    }

    if (notAvailableInThisRegion) {
      return {
        label: <Trans i18nKey="common.notAvailableInRegion.error" />,
        disabled: true,
        onClick: undefined,
        Component: ButtonPrimary,
      }
    }

    return {
      label: <Trans i18nKey="common.continue.button" />,
      disabled: false,
      // disabled: Boolean(fetchingQuotes || !quotes || !quotes.quotes || quotes.quotes.length === 0 || error),
      Component: ButtonPrimary,
      onClick: () => {
        setSellFormState((prev) => ({ ...prev, providerModalOpen: true }))
      },
    }
  }, [
    account.isConnected,
    inputAmount,
    forceDisabled,
    notAvailableInThisRegion,
    fetchingQuotes,
    quotes,
    error,
    accountDrawer.open,
    setSellFormState,
  ])
  return (
    <sellButtonState.Component fontWeight={535} disabled={sellButtonState.disabled} onClick={sellButtonState.onClick}>
      {sellButtonState.label}
    </sellButtonState.Component>
  )
}
