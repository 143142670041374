import { Body1 } from 'pages/Landing/components/Generics'
import { Logo, Telegram, Twitter } from 'pages/Landing/components/Icons'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

const MenuItemStyles = css`
  padding: 0;
  margin: 0;
  text-align: left;
  font-size: inherit;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: ${({ theme }) => theme.white};
  stroke: none;
  transition: color 0.1s ease-in-out;
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.neutral1};
    opacity: 1;
  }
`

const StyledLink = styled(Link)`
  ${MenuItemStyles}
`

const SocialsWrapper = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;

  @media (max-width: 768px) {
    justify-content: flex-start;
  }
`

export function Socials() {
  return (
    <SocialsWrapper>
      <StyledLink to="https://twitter.com/keytrust_one" target="_blank">
        <Twitter />
      </StyledLink>
      <StyledLink to="https://t.me/keytrust_one" target="_blank">
        <Telegram />
      </StyledLink>
    </SocialsWrapper>
  )
}

const FooterWrapper = styled.footer`
  display: grid;
  padding: 0 40px;
  width: 100%;
  max-width: 1360px;
  margin-bottom: 50px;
  gap: 50px;
  grid-template-columns: 1fr auto auto;

  @media (max-width: 768px) {
    gap: 20px;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
  }
`

const FooterBock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 768px) {
    gap: 6px;
  }
`

const BlockTitle = styled(Body1)`
  opacity: 0.7;
  font-weight: 400;
`

const LogoWrapper = styled(StyledLink)`
  height: min-content;
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
`

export function Footer() {
  return (
    <FooterWrapper>
      <LogoWrapper to="/">
        <Logo />
        KeyTrust
      </LogoWrapper>
      <FooterBock>
        <BlockTitle>Drop us a line at</BlockTitle>
        <Body1>
          <StyledLink to="mailto:partners@keytrust.com">partners@keytrust.com</StyledLink>
        </Body1>
      </FooterBock>
      <FooterBock>
        <BlockTitle>Our socials</BlockTitle>
        <Socials />
      </FooterBock>
    </FooterWrapper>
  )
}
