import { AnimatedSlider } from 'components/AnimatedSlider'
import { NavDropdown, NavDropdownDefaultWrapper } from 'components/NavBar/NavDropdown'
import { CurrencySettings } from 'components/NavBar/PreferencesMenu/Currency'
import { LanguageSettings } from 'components/NavBar/PreferencesMenu/Language'
import { PreferenceSettings } from 'components/NavBar/PreferencesMenu/Preferences'
import { PreferencesView } from 'components/NavBar/PreferencesMenu/shared'
import { useActiveLocalCurrency } from 'hooks/useActiveLocalCurrency'
import { useActiveLocale } from 'hooks/useActiveLocale'
import { useCallback, useState } from 'react'
import styled from 'styled-components'
import { Popover } from 'ui/src'
import { Global } from 'ui/src/components/icons'

export function getSettingsViewIndex(view: PreferencesView) {
  if (view === PreferencesView.SETTINGS) {
    return 0
  } else if (view === PreferencesView.LANGUAGE) {
    return 1
  } else {
    return 2
  }
}

const TriggerContent = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  font-family: 'Space Grotesk', 'Space', sans-serif;
  cursor: pointer;
`

export function PreferenceMenu() {
  const activeLocale = useActiveLocale()
  const activeLocaleCode = activeLocale.split('-')[0].toUpperCase()
  const activeLocalCurrency = useActiveLocalCurrency()

  const [settingsView, setSettingsView] = useState<PreferencesView>(PreferencesView.SETTINGS)
  const [isOpen, setIsOpen] = useState(false)
  const handleExitMenu = useCallback(() => setSettingsView(PreferencesView.SETTINGS), [setSettingsView])
  const onOpenChange = useCallback(
    (open: boolean) => {
      setIsOpen(open)
      if (!open) {
        handleExitMenu()
      }
    },
    [handleExitMenu, setIsOpen],
  )

  return (
    <Popover placement="bottom" stayInFrame allowFlip onOpenChange={onOpenChange}>
      <Popover.Trigger>
        <TriggerContent>
          <Global size={20} color="$neutral2" cursor="pointer" />
          <div>{activeLocaleCode}</div>
          <div>·</div>
          <div>{activeLocalCurrency}</div>
        </TriggerContent>
      </Popover.Trigger>
      <NavDropdown width={310} isOpen={isOpen}>
        <NavDropdownDefaultWrapper>
          <AnimatedSlider
            currentIndex={getSettingsViewIndex(settingsView)}
            slideDirection={settingsView === PreferencesView.SETTINGS ? 'forward' : 'backward'}
          >
            <PreferenceSettings setSettingsView={(view: PreferencesView) => setSettingsView(view)} />
            <LanguageSettings onExitMenu={handleExitMenu} />
            <CurrencySettings onExitMenu={handleExitMenu} />
          </AnimatedSlider>
        </NavDropdownDefaultWrapper>
      </NavDropdown>
    </Popover>
  )
}
