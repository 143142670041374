import { ButtonPrimary } from 'components/Button'
import { ConnectingViewWrapper } from 'pages/Swap/Buy/shared'
import { Trans } from 'react-i18next'
import { Flex, Text, useIsDarkMode } from 'ui/src'
import { ServiceProviderLogoStyles } from 'uniswap/src/features/fiatOnRamp/constants'
import { FORServiceProvider } from 'uniswap/src/features/fiatOnRamp/types'
import { getOptionalServiceProviderLogo } from 'uniswap/src/features/fiatOnRamp/utils'

interface ProviderConnectedViewProps {
  closeModal: () => void
  selectedServiceProvider: FORServiceProvider
  url: string
}

export function ProviderConnectedView({ closeModal, selectedServiceProvider, url }: ProviderConnectedViewProps) {
  const isDarkMode = useIsDarkMode()

  const onClick = (): void => {
    window.open(url, '_blank')
  }
  return (
    <ConnectingViewWrapper closeModal={closeModal}>
      <img
        style={ServiceProviderLogoStyles.uniswapLogoWrapper}
        height={120}
        src={getOptionalServiceProviderLogo(selectedServiceProvider?.logos, isDarkMode)}
        width={120}
      />
      <Flex flexDirection="column" alignItems="center" gap="10px">
        <Text variant="subheading1" fontWeight="700">
          <Trans
            i18nKey="fiatOnRamp.completeTransactionHeader"
            values={{ serviceProvider: selectedServiceProvider.name }}
          />
        </Text>
        <Text variant="body2" textAlign="center" color="$neutral2" fontWeight="500" lineHeight="18px">
          <Trans i18nKey="fiatOnRamp.continueInTab" values={{ serviceProvider: selectedServiceProvider.name }} />
        </Text>
        {/* <Row gap="md" justify="center">
          {PREDEFINED_AMOUNTS.map((amount: number) => (
            <PredefinedAmount
              onClick={() => {
                setBuyFormState((state) => ({ ...state, inputAmount: amount.toString() }))
              }}
              key={amount}
              amount={amount}
              currentAmount={inputAmount}
              disabled={disabled}
            />
          ))}
        </Row> */}
        <ButtonPrimary onClick={onClick} style={{ textTransform: 'uppercase', marginTop: '24px' }}>
          <Trans i18nKey="common.buy.link" />
        </ButtonPrimary>
      </Flex>
    </ConnectingViewWrapper>
  )
}
