import { Telegram, Twitter } from 'pages/Landing/components/Icons'
import styled, { css } from 'styled-components'
import { ExternalLink } from 'theme/components'

const MenuItemStyles = css`
  padding: 0;
  margin: 0;
  text-align: left;
  font-family: Basel, Space, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: ${({ theme }) => theme.neutral2};
  stroke: none;
  transition: color 0.1s ease-in-out;
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.neutral1};
    opacity: 1;
  }
`

const StyledExternalLink = styled(ExternalLink)`
  ${MenuItemStyles};
  display: flex;
  gap: 5px;
  align-items: center;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`

export function HeaderSocials() {
  return (
    <Wrapper>
      <StyledExternalLink href="https://twitter.com/keytrust_one">
        <Twitter /> — we do fun crypto stuff!
      </StyledExternalLink>
      <StyledExternalLink href="https://t.me/keytrust_one">
        <Telegram /> — our blog on finance
      </StyledExternalLink>
    </Wrapper>
  )
}
