import { Currency } from '@uniswap/sdk-core'
import { AssetLogoBaseProps } from 'components/Logo/AssetLogo'
import BlockchainAssetLogo from 'components/Logo/BlockchainAssetLogo'

export default function EditedCurrencyLogo(
  props: AssetLogoBaseProps & {
    currency?: Currency | null
    logo?: string | null
  },
) {
  return (
    <BlockchainAssetLogo
      currency={props.currency}
      isNative={props.currency?.isNative}
      chainId={props.currency?.chainId}
      // address={props.currency?.wrapped.address}
      symbol={props.symbol ?? props.currency?.symbol}
      primaryImg={props.logo}
      {...props}
    />
  )
}
